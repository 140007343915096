import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Header.css";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div>
        <div className="navbar">
          <Navbar
            style={{ backgroundColor: "#525A5E", color: "white" }}
            variant="dark"
            fixed="top"
            expand="lg"
          >
            <Container>
              <Navbar.Brand href="/">DABF, Trust IT</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link component={NavLink} href="/">
                    Home
                  </Nav.Link>
                  <Nav.Link component={NavLink} href="/#about">
                    About Us
                  </Nav.Link>
                  <Nav.Link component={NavLink} href="/#services">
                    Services
                  </Nav.Link>
                  <Nav.Link component={NavLink} href="/usermanual">
                    User Manual
                  </Nav.Link>
                  <Nav.Link component={NavLink} href="/#team">
                    Team
                  </Nav.Link>
                  <Nav.Link component={NavLink} href="/#contact">
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <br />
        <div
            id="about"
            style={{
              backgroundColor: "#F7F7F7",
            }}
              >
            <Container maxWidth="lg">
              
                <br />
                <br />
                {!isMobile && <br />}
                <Container maxWidth="lg" padding={isMobile ? 0 : 1}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12} md={12}></Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <h1
                        style={{
                          textAlign: "left",
                          // margin: "8px 0",
                          fontWeight: "bold",
                          paddingBottom: "10px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "500",
                        }}
                      >
                        Welcome To,
                      </h1>
                      <h1
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          fontSize: isMobile ? "32px" : "56px",
                          color: "#12B176",
                          border: "2px solid #12B176",
                        }}
                      >
                        DOCTOR ASHIT BARDHAN FOUNDATION (Trust)
                      </h1>
                      <h3
                        style={{
                          textAlign: "center",
                          margin: "10px 0",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "500",
                          backgroundColor: "#12B176",
                          color: "white",
                        }}
                      >
                        <span style={{ fontSize: "30px" }}> Trust It. </span>{" "}
                        (An Auxiliary Organization)
                      </h3>
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                  </Grid>

                  <Grid container rowSpacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      // padding={1}
                      style={{
                        borderLeft: "6px solid #12B176",
                        // backgroundColor: '#F7F7F7',
                      }}
                    >
                      <Box paddingTop={1} paddingLeft={1}>
                        <Box
                          style={{
                            backgroundColor: "#F7F7F7",
                            marginBottom: "10px",
                            border: "2px solid #12B176",
                          }}
                        >
                          <Typography
                            variant={"text"}
                            align={isMobile ? "center" : "center"}
                            fontFamily="Roboto, sans-serif"
                            component="p"
                            fontSize={isMobile ? "18px" : "25px"}
                          >
                            We are the first producer of <span style={{ fontWeight: "bold" }}> 'EMRS'</span> with concern
                            department from 2013 till now.
                          </Typography>
                        </Box>

                        <Box
                        style={{
                          backgroundColor: "#F7F7F7",
                          marginBottom: "10px",
                          border: "2px solid #12B176",
                        }}
                      >
                        <Typography
                          variant={"text"}
                          align={isMobile ? "center" : "center"}
                          fontFamily="Roboto, sans-serif"
                          component="p"
                          fontSize={isMobile ? "18px" : "24px"}
                        >
                        We have proposed our government (PPP){" "}
                        <span style={{ fontWeight: "bold" }}>
                          'One Country, One Identity Based Treatment System'
                        </span>{" "}
                        in Bangladesh.
                        </Typography>
                      </Box>
                      <Box
                      style={{
                        backgroundColor: "#F7F7F7",
                        marginBottom: "10px",
                        border: "2px solid #12B176",
                      }}
                    >
                      <Typography
                        variant={"text"}
                        align={isMobile ? "center" : "center"}
                        fontFamily="Roboto, sans-serif"
                        component="p"
                        fontSize={isMobile ? "18px" : "24px"}
                      >
                      You are always welcome with <span style={{ fontWeight: "bold" }}>'No Financial Investment' </span> 
                       as a partner of our software to build smart
                      Bangladesh.
                      </Typography>
                    </Box>
                       
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
                <br />
                <br />
             
          </Container>
          </div>
        

        <Container maxWidth="lg">
        <div
          id="about"
        >
          {!isMobile && <br />}
          <Container maxWidth="lg" padding={isMobile ? 0 : 1}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12} md={12}></Grid>
              <Grid item xs={12} md={12} lg={12}>
              
                <h1
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "bold",
                    fontSize: isMobile ? "32px" : "50px",
                    color: "#12B176",
                    // border: "2px solid #12B176",
                  }}
                >
                ডা : অসিত বর্ধন ফাউন্ডেশন (ট্রাস্ট) এ আপনাকে স্বাগতম।
                </h1>
                <h3
                  style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "500",
                    backgroundColor: "#12B176",
                    color: "white",
                  }}
                >
                  <span style={{ fontSize: "30px" }}> ট্রাস্ট আইটি (অঙ্গপ্রতিষ্ঠান) </span>{" "}
                  
                </h3>
              </Grid>
              <Grid item xs={12} md={12}></Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                // padding={1}
                style={{
                  borderLeft: "6px solid #12B176",
                  // backgroundColor: '#F7F7F7',
                }}
                >
                <Box paddingTop={1} paddingLeft={1}>  
                <Box
                style={{
                  backgroundColor: "#F7F7F7",
                  marginBottom: "10px",
                  border: "2px solid #12B176",
                }}
              >
                <Typography
                  variant={"text"}
                  align={isMobile ? "center" : "center"}
                  fontFamily="Roboto, sans-serif"
                  component="p"
                  fontSize={isMobile ? "18px" : "24px"}
                >
                ২০১৩ সাল থেকে দেশের সংশ্লিষ্ট মহলে আমরাই প্রথম ইলেকট্রনিক্স মেডিকেল রেকর্ড সিস্টেমের গুরুত্ব উপস্থাপন করি ।
                </Typography>
                    </Box>
                    </Box>
                <Box paddingTop={1} paddingLeft={1}>
                  <Box
                    style={{
                      backgroundColor: "#F7F7F7",
                      marginBottom: "10px",
                      border: "2px solid #12B176",
                    }}
                  >
                    <Typography
                      variant={"text"}
                      align={isMobile ? "center" : "center"}
                      fontFamily="Roboto, sans-serif"
                      component="p"
                      fontSize={isMobile ? "18px" : "25px"}
                    >
                    আমরাই বাংলাদেশে 'এক দেশ এক আইডিতে' একিভুক্ত চিকিৎসা ব্যবস্থা
                    নিশ্চিতকরণের জন্য এর প্রথম এবং একমাত্র প্রস্তাবক ।
                    </Typography>
                  </Box>
                <Box
                style={{
                  backgroundColor: "#F7F7F7",
                  marginBottom: "10px",
                  border: "2px solid #12B176",
                }}
              >
                <Typography
                  variant={"text"}
                  align={isMobile ? "center" : "center"}
                  fontFamily="Roboto, sans-serif"
                  component="p"
                  fontSize={isMobile ? "18px" : "24px"}
                >
                আপনার আন্তরিকতা থাকলে স্মার্ট বাংলাদেশ গঠনে আমরা একত্রে কাজ করতে
              পারি ।
                </Typography>
              </Box>
                 
                </Box>
              </Grid>
            </Grid>
          </Container>
          <br />
          <br />
        </div>
      </Container>
      </div>
    </>
  );
};

export default Header;
