import React, { Fragment } from 'react';
import Contact from '../Layout/Contact/Contact';
import Footer from '../Layout/Footer/Footer';
import Header from '../Layout/Header/Header';
import Product from '../Layout/Product/Product';
import Team from '../Layout/Team/Team';
import GetConnected from '../Layout/GetConnected/GetConnected';
const Home = () => {
    return (
        <Fragment>
            <Header />
            {/* <Words /> */}
            {/* <Facts /> */}
            <Product />
            {/* <Portfolio /> */}
            <GetConnected />
            <Team />
            <Contact />
            <Footer />
        </Fragment>
    );
};

export default Home;