import account from './images/account.jpg';
import amrito from './images/amrito.jpeg';
import asif from './images/asif.JPEG';
import asit from './images/asit.png';
import booking from './images/booking.png';
import clinicManage from './images/clinic-manage.jpg';
import clinic from './images/clinic.jpg';
import doctor from './images/doctor.jpg';
import email from './images/email.jpg';
import jahirul from './images/JahirulIslam.png';
import khorsed from './images/khorshed.jpg';
import sms from './images/messageing.webp';
import mina from './images/minara .jpg';
import moon from './images/moon.jpg';
import rahat from './images/rahat.jpg';
import rakib from './images/rakibul.jpg';
import sabuj from './images/sabuj.jpg';
import shadesh from './images/sadesh.jpg';
import school from './images/school.jpg';
import shoharab from './images/shoharab.jpeg';
import rakibulHasan from "./images/rakibul-hasan.jpg";
import nurNoby from "./images/nurNoby.png";
import ronyAhmed from "./images/ronyAhmed.png";
import KonokMajamder from "./images/konokMajamder.png";
import mithon from "./images/mithon.png";
import xray from "./images/xray.jpg";
import zim from "./images/zim.jpg";

export const productData = [
  {
    label: "Accounts",
    link: "https://accounts.bdemr.com/",
    image: account,
    id: "123456aads",
    features: [
      { label: "Stock Management System" },
      { label: "Profit & Loss Management" },
      { label: "Easy Due collection & Due report" },
      {
        label:
          "Loan Report/ Loan Management and Automated Loan Repayment calculations",
      },
      { label: "Easy Sales Return" },
      { label: "Easy Purchase Return" },
      { label: "SMS Billing Facilities" },
      { label: "Print Billing Facilities " },
      { label: "HR Management with Salary Sheets " },
      { label: "Bank Account Adjustment and Management" },
      { label: "Multifunctional Payroll facilities" },
      { label: "Bulk SMS facilities for Marketing & Due collection" },
      { label: "Dynamic play store app facilities. " },
    ],
  },
  {
    label: "BDEMR Doctor APP",
    link: "https://doctor.bdemr.com",
    image: doctor,
    id: "123457aa",
    features: [
      { label: "Daily Booked patients view" },
      { label: "Easy facilities of Patients Medical History Creation" },
      { label: "Patients Investigation/ Diagnosis Report View" },
      { label: "Hassle Free Prescription Create. " },
      { label: "Sending Prescriptions digitally to patient. " },
      { label: "Operation Schedule Management for Doctors." },
      { label: "Pre Op and Post Op advice entry for patients" },
      {
        label:
          "Discharge patients with proper advise and prescription from doctor app.",
      },
      {
        label:
          "Direct video call to patient without sharing personal Data of Doctors.",
      },
      { label: "Bulk SMS sending facilities for promotion" },
      { label: "Wallet based payment management system and facilities" },
      { label: "Dynamic Play Store app for Doctor." },
      {
        label:
          "Easy to access play store app for patients regarding all the data records.",
      },
    ],
  },
  {
    label: "Hospital/Clinic Management Software",
    link: "https://clinic.bdemr.com",
    image: clinicManage,
    id: "123458ffsd",
    features: [
      { label: "Outdoor Patient Management " },
      { label: "Indoor Patient Management" },
      { label: "Stock Management" },
      { label: "Accounts Management" },
      { label: "Pharmacy Management" },
      { label: "Operation Theatre Management" },
      { label: "ICU/ CCU Management facilities" },
      { label: "Referred Doctor payment facilities with proper Report" },
      { label: "Third Party Payment Facilities" },
      { label: "Reagent Management for Laboratory" },
      { label: "Fund Based Discount and Fund Facilities" },
      { label: "Lifetime Patient Data Stored facilities" },
      { label: "Pathology/ Lab/ Investigation Reporting facilities" },
      {
        label:
          "Booking management for Hospital Chambers with SMS sending facilities",
      },
      { label: "BULK SMS for promotion and Due collection" },
      { label: "HR Management" },
      { label: "Ambulance Management" },
      { label: "Canteen Management" },
      {
        label:
          "Patient Admission with Waitlist and Operation management with updated status and location",
      },
      { label: "Individual item wise billing report with total numbers" },
      { label: "Due and Discount Report" },
      { label: "Dynamic Clinic Management App in Play Store" },
      {
        label:
          "Individual Role management like Lab technician, Accountant, HR, Front Desk privileges.",
      },
    ],
  },
  {
    label: "Doctor Appointment Booking App",
    link: "https://doctor.bdemr.com",
    image: booking,
    id: "123459asdfga",
    features: [
      {
        label:
          "Doctor/ Hospital/ Clinic/ Diagnostic Centre front desk advantage",
      },
      { label: "Easy Way of creating a new doctors chamber" },
      { label: "Advanced Doctor Appointment Facility" },
      { label: "Print out doctors serial" },
      { label: "SMS booking system" },
      { label: "Promotional SMS facility" },
      {
        label:
          "Wallet Based payment system *without service Charge/ Minimum OTP",
      },
      { label: "Easy to gett patient app in play store of appointment." },
      { label: "Dynamic Booking & Patient app in Play Store" },
    ],
  },
  {
    label: "BDEMR Image Reporting System",
    link: "https://xray.trustit.digital",
    image: xray,
    id: "123460adgdf",
    features: [
      { label: "Online X-Ray Report view and Report generate system" },
      { label: "Online CT-Scan Report Making facility" },
      { label: "Online MRI Report creation Facility" },
      { label: "Online Engiogram (Video Base) Diagnosis System" },
      { label: "Bulk SMS for Promotional Support" },
      { label: "Wallet Based Payment Method" },
      { label: "Doctor & Doctor assistant privilege facility" },
      { label: "Report upload facility for doctors view" },
      { label: "Easy payment methods for Doctors" },
      { label: "Multiple Organisation report facility" },
      { label: "Dynamic Image Reporting App in Play Store" },
    ],
  },
  {
    label: "BDEMR School Management Software",
    link: "http://www.bdemr.schoolmanagement.com",
    image: school,
    id: "123461adfg",
    features: [
      { label: "Total Accounts of School" },
      { label: "Salary Sheet, Admission Fee and Monthly Income" },
      { label: "Student Exam Panel" },
      { label: "Student Result Panel" },
      { label: "Bulk SMS forwarding facility for Promotion and Notification" },
      { label: "School Notice Board" },
      { label: "Student Attendance panel with Marking" },
      { label: "Teachers Attendance" },
      { label: "Class Schedule" },
      { label: "Dynamic Dedicated app in play store" },
    ],
  },
  {
    label: "BDEMR SMS Forwarding Software",
    link: "https://www.sms.bdemr.com",
    image: sms,
    id: "123462agdf",
    features: [
      { label: "Bulk SMS facilities" },
      { label: "Cheapest SMS only at 0.04p per SMS" },
      { label: "5000/- Installation Charge" },
      { label: "Promotional bulk SMS" },
      { label: "Saved Number facilities" },
      { label: "Anytime SMS sending option " },
      { label: "Dedicated Dynamic App in Play Store" },
    ],
  },
  {
    label: "BDEMR Email Responder App",
    link: "http://email.trustit.digital",
    image: email,
    id: "123463rgta",
    features: [
      { label: "A secured mail for automated response" },
      { label: "Our very own vps, so no fear of data expose." },
      { label: "Our own mail service" },
      { label: "300 mails at a time per day" },
      { label: "Selective time period of auto mail forwarding service" },
      { label: "Template based mail reply facility" },
      { label: "100% secured account" },
      {
        label:
          "Any kind of responsive template set for automated mailing response",
      },
      {
        label:
          "Marketing, Notify team members, Corporate mailing service facilities",
      },
    ],
  },
  {
    label: "BDEMR Patient Management APP",
    link: "https://patient.bdemr.com",
    image: clinic,
    id: "123464dfgsd",
    features: [
      { label: "Easy create account" },
      { label: "Doctors from all over Bangladesh" },
      { label: "Specialised Doctors" },
      { label: "Anytime doctors appointment booking facilities" },
      { label: "Free to use" },
      {
        label:
          "Doctor visit payment directly from e-banking and mobile banking.",
      },
      { label: "Prescription view and printing. " },
      { label: "Personalised search facilities to find the doctor" },
      { label: "Doctor Profile " },
      { label: "Direct video calling with doctor" },
      { label: "Telemedicine facilities. " },
    ],
  },
];

export const companyImg = [
  {
    name: "Ashit Bardhan",
    designation: "Chairman",
    designation2: "Dr Ashit Bardhan Foundation (Trust)",
    image: asit,
  },
  {
    name: "Subir Bardhan Moon",
    designation: "CEO, Trust It",
    designation2: "Executive Director , Dr Ashit Bardhan Foundation (Trust)",
    image: moon,
  },
];

export const devTeam = [
  {
    name: "Asif Alam",
    designation: "Management & Business Co-ordinator ",
    education: "B.Sc in Radiology",
    image: asif,
  },
  {
    name: "Sakib Uddin Ahmed",
    designation: "Senior Software Engineer & Team Lead",
    education: "M.Sc in Computer Science & Engineering",
    image: rahat,
  },
  {
    name: "Khaja Sheikh Imran Sabuj",
    designation: "Software Engineer & Co Team Lead",
    education: "B.Sc in Computer Science & Engineering",
    image: sabuj,
  },
  // {
  //     name: "Md Shoharab Pk",
  //     designation: "MERN Stack Developer",
  //     image: shoharab
  // },
  {
    name: "Jahirul Islam",
    designation: "Software Engineer",
    education: "B.Sc in Computer Science & Engineering",
    image: jahirul,
  },
  {
    name: "Shadesh",
    designation: "Software Engineer",
    education: "M.Sc in Computer Science & Engineering",
    image: shadesh,
  },
  {
    name: "Rony Ahmed",
    designation: "Software Engineer & Machine Readability specialist",
    education: "B.Sc in Computer Science & Engineering",
    image: ronyAhmed,
  },
  {
    name: "Konok Majamder",
    designation: "Software Engineer",
    education: "B.Sc in Computer Science & Engineering",
    image: KonokMajamder,
  },
  {
    name: "Rakibul Hasan",
    designation: "Software Engineer",
    education: "B.Sc in Computer Science & Engineering",
    image: rakibulHasan,
  },
  {
    name: "Nur Noby",
    designation: "Software Engineer",
    education: "B.Sc in Computer Science & Engineering",
    image: nurNoby,
  },
  //   {
  //     name: "Amrito Kumar",
  //     designation: "Technical Support",
  //     image: amrito,
  //   },
  {
    name: "Minara",
    designation: "Sales Support Executive",
    education: "MA Pass",
    image: mina,
  },
  {
    name: "Md. Mithun Pk",
    designation: "Technical officer",
    education: "University Graduate",
    image: mithon,
  },
  {
    name: "Rakib",
    designation: "Marketing Team",
    education: "University Graduate",
    image: rakib,
  },
  {
    name: "Kh Zim Hossain",
    designation: "Technical Manager",
    education: "University Graduate",
    image: zim,
  },
];

export const userData = [
    {
        label: "Accounts",
        link: 'https://docs.google.com/document/d/15Vq76_lx4CbYQZaXmhCHXK56CzidLBXNHYuqRfBaW84/edit?usp=sharing',
        image: account,
        id: 123456,
    },
    {
        label: "BDEMR Doctor APP",
        link: 'https://docs.google.com/document/d/1CrJovMz2Jzjt0MxXtXMFVMdFHDsyf40BSGGCXYlxzv8/edit',
        image: doctor,
        id: 123457 
    },
    {
        label: "Hospital/Clinic Management Software",
        link: 'https://docs.google.com/document/d/1VKZg40pPDlXyXYwHI9Z4LPse_zvxYFAE4CXUCPvbVZ8/edit',
        image: clinicManage,
        id: 123458,
    },
    {
        label: "Doctor Appointment Booking App",
        link: 'https://docs.google.com/document/d/1ZEDV8kY1ifmTi86EgFYshKe5yQZ3kU-fEYYoLe3FVkg/edit?usp=sharing',
        image: booking,
        id: 123459 
    },
    {
        label: "BDEMR Image Reporting System",
        link: 'https://docs.google.com/document/d/1yXmpbv7ryN-tjpteZuP-magUABNtrheOzT20EmaoApQ/edit?usp=sharing',
        image: xray,
        id: 123460
    },
    {
        label: "BDEMR School Management Software",
        link: 'https://docs.google.com/document/d/1oaZqujemOsAWUPPX_xFqiPOCErVv-k8Es_SGVJYdxAc/edit?usp=sharing',
        image: school,
        id: 123461 
    },
    {
        label: "BDEMR SMS Forwarding Software",
        link: 'https://docs.google.com/document/d/1-h2I-4dYLP1twT9-E8jlxxUY28OHsH7HD8TTVriscIk/edit?usp=sharing',
        image: sms,
        id: 123462,
    },
    {
        label: "BDEMR Email Responder",
        link: 'https://docs.google.com/document/d/1O48QrIqtb-uEK-RgGc1wf7x2HLWIUk9mFn0p1eKGnDA/edit?usp=sharing',
        image: email,
        id: 123463 
    },
    {
        label: "BDEMR Patient APP",
        link: 'https://docs.google.com/document/d/1Hne9bs36Acf7bRfpDi7t94OMALHzT25TZupz9WpAkNQ/edit?usp=sharing',
        image: clinic,
        id: 123464, 
    },
]