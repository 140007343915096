import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Header.css";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
const GetConnected = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
        <Container maxWidth="lg">
        <Grid
        container
        // rowSpacing={1}
        style={{
          paddingTop: isMobile ? "0px" :"10px",
          paddingBottom: isMobile ? "40px" : "50px",
          marginTop: isMobile ? "5px" : "0px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Roboto, sans-serif",
            fontSize: isMobile ? "28px" : "56px",
            color: "#12B176",
            fontWeight: "bold",
            paddingTop: isMobile ? "20px" :"10px",
            paddingBottom: isMobile ? "5px" : "10px",
          }}
        >
          <h1>GET CONNECTED</h1>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isMobile ? "1px" : "20px",
            paddingBottom:  isMobile ? "1px" : "20px",
          }}
        >
          <a
            href="https://www.facebook.com/sbmoon.moon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton >
              <FacebookIcon />
            </IconButton>
          </a>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: isMobile ? "16px" : "20px",
              color: "#12B176",
              fontWeight: "bold",
            }}
          >
            Subir Bardhan Moon
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isMobile ? "1px" : "20px",
            paddingBottom:  isMobile ? "1px" : "20px",
          }}
        >
          <IconButton >
            <EmailIcon />
          </IconButton>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: isMobile ? "16px" : "20px",
              color: "#12B176",
              fontWeight: "bold",
            }}
          >
            sbmoon771@gmail.com
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: isMobile ? "1px" : "20px",
            paddingBottom:  isMobile ? "1px" : "20px",
          }}
        >
          <IconButton >
            <PhoneIcon />
          </IconButton>
          <Typography
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: isMobile ? "16px" : "20px",
              color: "#12B176",
              fontWeight: "bold",
            }}
          >
            +8801713735835
          </Typography>
        </Grid>
      </Grid>
        </Container>
  );
};

export default GetConnected;
