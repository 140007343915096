 
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './components/Home/Home';
import ProductDetails from './components/ProductDetails/ProductDetails';
import UserManual from './components/UserManual/UserManual';

function App() {
  return (
    <div>
       <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/details/:id" element={<ProductDetails />} />
            <Route path="/usermanual" element={<UserManual
             />} />
          </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
