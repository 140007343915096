import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Contact.css';
import axios from 'axios' 
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = useState()

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value })
    }



    const sendEmail = (e) => {
        e.preventDefault() 
        const config =  {headers: {'Content-Type': 'text/plain'}}
        axios.post('https://bdemr.services/api/1/bdemr-babf-send-contact-email', data, config)
            .then((res) => {
 
                if(!res.data.hasError) {
                    alert(res.data.data.message);
                    window.location.reload()
                } return

                
            })
    }

    return (
      <Fragment>
        <div id="contact" className="contact">
          <Container>
            <h1 className="contact-title" style={{
              fontWeight: "bold",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          fontSize: isMobile ? "32px" : "56px",
                          color: "#12B176",
            }}>CONTACT</h1>
            {/* <p className="contact-title-p">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p> */}
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                {/* <iframe
                  title="Trust IT"
                  src="https://www.google.com/maps/d/embed?mid=1-X7wEYbDsiloreoYReakLHKY7hXt-2cH&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe> */}
                <iframe
                  title="Trust IT"
                  src="https://www.google.com/maps/d/embed?mid=1eaYJJAHSMYD5QVJIy_FyXaaJCElGeYQ&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <div className="contact-info">
                  <div className="address_card">
                    <p>Dhaka Office:</p>
                    <div>
                      <i class="fa fa-map-marker"></i>
                      <p>
                      House: M-28 Road-4, Eastern Housing N Blook Alwkdi Mirpur -12 ,
                        <b /> Dhaka 1216, Bangladesh
                      </p>
                    </div>
                  </div>
                  <div className="address_card">
                    <p>Natore Office:</p>
                    <div>
                      <i class="fa fa-map-marker"></i>
                      <p>
                        Trust It, House # 1, Road #6, Kanaikhali Zale Para
                        <b /> Natore - 6400, Bangladesh
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <div class="form">
                  <form onSubmit={sendEmail} class="contactForm">
                    <input
                      onChange={handleChange}
                      required
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <input
                      onChange={handleChange}
                      required
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <input
                      onChange={handleChange}
                      required
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <textarea
                      onChange={handleChange}
                      required
                      class="form-control"
                      name="message"
                      rows="7"
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                    ></textarea>
                    <div>
                      <button className="sendBtn"> Send Message</button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
};

export default Contact;