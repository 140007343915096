import React, { Fragment } from 'react';
import { userData } from '../../data';
import Footer from '../Layout/Footer/Footer';
import Header from '../Layout/Header/Header';
import './UserManual.css'

const UserManual = () => {
    return (
        <Fragment>
            <Header />
                <section>
                    { 
                    userData.map((details) => (
                        <div className="user-manual">
                        <div>
                            <h1>{details?.label}</h1>
                            <a href={`${details?.link}`} target="_blank" rel="noreferrer">
                                <button className="user-details-btn">User Manual</button>
                            </a>
                        </div>
                        <img src={details?.image} alt="" />
                    </div>
                    ))
                    }
               
                </section>
            <Footer />
        </Fragment>
    );
};

export default UserManual;