import React, { Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { productData } from '../../../data';
import './Product.css';
import image1 from './../../../images/applogo/patient.webp';
import image2 from './../../../images/applogo/bdemr.webp';
import image3 from './../../../images/applogo/clinic.png';
import image4 from './../../../images/applogo/doctor.png';
import image5 from './../../../images/applogo/anaesmon.png';
import image6 from './../../../images/applogo/doctor.png';
import image7 from './../../../images/applogo/bdemr.webp';

const data = [
    {
        image: image1,
        name: 'Patient',
        link: 'http://patient.trustit.digital/',
    },
    {
        image: image2,
        name: 'Account',
        link: 'http://account.trustit.digital/',
    },
    {
        image: image3,
        name: 'Clinic',
        link: 'http://clinic.trustit.digital/',
    }
    ,
    {
        image: image4,
        name: 'Doctor',
        link: 'http://doctor.trustit.digital/',
    },
    {
        image: image5,
        name: 'Email',
        link: 'http://email.trustit.digital/',
    },
    {
        image: image6,
        name: 'XRay',
        link: 'http://xray.trustit.digital/',
    },
    {
        image: image7,
        name: 'Booking',
        link: 'http://booking.trustit.digital/',
    }
]

const Product = () => {
    return (
      <Fragment>
        <div id="services" className="bannderSection" style={{
          backgroundColor: "#F7F7F7",
        }}>
          <Container>
            <div>
              <div >
                <br />
                <h1 style={{ textAlign: "center", border:"3px solid #12B176", color: "#12B176",}}>
                  We Are Ready To Provide
                </h1>
                <div className="products">
                  <Row>
                    {productData.map((product) => (
                      <Col
                        key={product.label}
                        className="product-card"
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        <Card className="card">
                          <Card.Img
                            className="product-img"
                            variant="top"
                            src={product.image}
                          />
                          <Card.Body>
                            <a
                              className="unlink"
                              target="_blank"
                              href={product.link}
                              rel="noreferrer"
                            >
                              <Card.Title>{product.label}</Card.Title>
                            </a>
                            <br />
                            <Link to={`/details/${product.id}`}>
                              <button className="product-btn">
                                For Details Click Me
                              </button>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
            <br />
          </Container>
        </div>
      </Fragment>
    );
};

export default Product;