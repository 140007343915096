import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { companyImg, devTeam } from '../../../data';
import './Team.css';

const Team = () => {
    return (
      <Fragment>
        <div id="team" className="team">
          <Container>
            <div>
              <br />

              <div className="ceo-team">
                {companyImg.map((team) => (
                  <div key={team.position}>
                    <div className="ceo-panel-Card">
                      <img src={team.image} alt="" />
                      <br />
                      <h5>{team.name}</h5>
                      <span>{team.designation}</span> <br />
                      <span>{team?.designation2}</span>
                      <div class="social-media">
                        <a href="/">
                          <i class="fa fa-twitter"></i>
                        </a>
                        <a href="/">
                          <i class="fa fa-google-plus"></i>
                        </a>
                        <a href="/">
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="dev-team">
                  {devTeam.map((team) => (
                    <div>
                      <div className="teamCard">
                        <img src={team.image} alt="" />
                        <h6>{team.name}</h6>
                        <span>{team.designation}</span>
                        <p>
                          <small>{team.education}</small>
                        </p>
                        <div class="social-media">
                          <a href="/">
                            <i class="fa fa-twitter"></i>
                          </a>
                          {/* <a href="/"><i class="fa fa-facebook"></i></a> */}
                          <a href="/">
                            <i class="fa fa-google-plus"></i>
                          </a>
                          <a href="/">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <br />
            </div>
          </Container>
        </div>
      </Fragment>
    );
};

export default Team;