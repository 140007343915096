import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { productData } from '../../data';
import Header from '../Layout/Header/Header';
import './ProductDetails.css';

const ProductDetails = () => {

    const { id } = useParams();

    const [details, setDetails] = useState({});

    console.log("id", id)
    const findProduct = async (id) => {
        console.log("f id", id)
        const details2 = productData.find((p) => {
            
            return p.id === id
        });

        setDetails(details2);
    }

    useEffect(() => {
        findProduct(id)
    }, [ id])

    if (!details?.link) {
        return <h1>Loading...</h1>
    }
    console.log("details", details)

    return (
        <Fragment>
            <Header />
            <div className="details-header">
                <div>
                    <h1>{details?.label}</h1>
                    <a href={`${details?.link}`} target="_blank" rel="noreferrer">
                        <button className="product-details-btn">Click Here</button>
                    </a>
                </div>
                <img src={details?.image} alt="" />
            </div>

            <div className="app-details">
                <h1>Our App Features</h1>

                <div>
                    <ul>
                        {
                            details?.features?.map((i) => (
                                <li>{i?.label}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductDetails;