import React, { Fragment } from 'react';
import './Footer.css';

const Footer = () => {
    return (
      <Fragment>
        <div className="footer">
          <p className="copyright">
            <i>&copy; Copyright {"  "} DABF, All Rights Reserved by BDEMR</i>
          </p>
        </div>
      </Fragment>
    );
};

export default Footer;